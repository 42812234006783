import LoginAction from "../actions/login.action";
import _ from "lodash";

const defaultState = {
    login: {
        isFetching: false
    }
};

const todoReducer = (state = defaultState, action) => {
    switch (action.type) {
        case LoginAction.Types.USER_LOGIN_REQUEST:
        case LoginAction.Types.USER_LOGIN_SUCCESS:
        case LoginAction.Types.USER_LOGIN_ERROR: {
            let newState = _.cloneDeep(state);
            newState.login = action.login;
            return newState;
        }

        case LoginAction.Types.USER_REGISTER_REQUEST:
        case LoginAction.Types.USER_REGISTER_SUCCESS:
        case LoginAction.Types.USER_REGISTER_ERROR: {
            let newState = _.cloneDeep(state);
            newState.login = action.login;
            return newState;
        }

        case LoginAction.Types.USER_ACTIVATE_REQUEST:
        case LoginAction.Types.USER_ACTIVATE_SUCCESS:
        case LoginAction.Types.USER_ACTIVATE_ERROR: {
            let newState = _.cloneDeep(state);
            newState.login = action.login;
            return newState;
        }
        default:
            return state;
    }
};

export default todoReducer;
