import EventAction from "../actions/event.action";
import _ from "lodash";

const defaultState = {
    isFetching: false,
    error: false,
    events: null,
    event: null
};

const eventReducer = (state = defaultState, action) => {
    switch (action.type) {
        case EventAction.Types.EVENT_FETCH_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.data.isFetching;
            return newState;
        }
        case EventAction.Types.EVENT_FETCH_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.data.isFetching;
            newState.events = action.data.events;
            return newState;
        }
        case EventAction.Types.EVENT_FETCH_ERROR: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.data.isFetching;
            newState.error = action.data.error;
            return newState;
        }

        case EventAction.Types.PARTICIPATED_EVENT_FETCH_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.data.isFetching;
            return newState;
        }
        case EventAction.Types.PARTICIPATED_EVENT_FETCH_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.data.isFetching;
            newState.participatedEvents = action.data.participatedEvents;
            return newState;
        }
        case EventAction.Types.PARTICIPATED_EVENT_FETCH_ERROR: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.data.isFetching;
            newState.error = action.data.error;
            return newState;
        }

        case EventAction.Types.SINGLE_EVENT_FETCH_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.data.isFetching;
            return newState;
        }
        case EventAction.Types.SINGLE_EVENT_FETCH_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.data.isFetching;
            newState.event = action.data.event;
            return newState;
        }
        case EventAction.Types.SINGLE_EVENT_FETCH_ERROR: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.data.isFetching;
            newState.error = action.data.error;
            return newState;
        }

        case EventAction.Types.PARTICIPATE_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.data.isFetching;
            return newState;
        }
        case EventAction.Types.PARTICIPATE_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.data.isFetching;
            return newState;
        }
        case EventAction.Types.PARTICIPATE_ERROR: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.data.isFetching;
            newState.error = action.data.error;
            return newState;
        }
        default:
            return state;
    }
};

export default eventReducer;
