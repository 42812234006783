import React from 'react';
import { Layout, Menu, Row, Col } from 'antd';
import Login from '../login/Login';
import {Redirect, Route, withRouter} from "react-router-dom";
import {connect} from "react-redux";

const { Header, Content, Footer } = Layout;

class HomeLayout extends React.Component {

    componentWillMount() {
        if(localStorage.getItem('auth') !== null){
            this.props.history.push('/dashboard');
        }
    }

    render() {
        return (
            <Layout className="layout home" style={{height: '100%'}}>
                <Header style={{background: '#0c161f'}}>
                    <div className="logo-holder">
                        <div className="logo"/>
                    </div>
                </Header>
                <Content>
                    <div style={{height: '100%'}}>
                        <Row type="flex" justify={"center"} style={{height: '100%', alignItems: 'center'}}>
                            <Col lg={6} xs={21}>
                                <Login/>
                            </Col>
                        </Row>
                    </div>

                </Content>
            </Layout>
        );
    }

}

const mapStateToProps = state => ({
    login: state.auth.login
});

export default connect(
    mapStateToProps
)(withRouter(HomeLayout));
