export default class LevelCalculator {
    static calcLevel(exp) {
        return Math.floor((exp + 1000) / 1000) + "";
    }

    static calcPercentageForNextLevel(exp) {
        const level = LevelCalculator.calcLevel(exp);
        const remainingExp = (exp + 1000) - (level * 1000);
        let result = 0;
        if (remainingExp > 0) {
            result = (remainingExp / 1000) * 100;
        } 
        return Math.floor(result);
    }
}