import Config from "../../config";

export default class ApiFetch {
    static get(route, withAuth, config) {
        config = ApiFetch.prepareConfig('GET', config, withAuth);
        return ApiFetch.apiRequest(route, config);
    }

    static post(route, withAuth, config) {
        config = ApiFetch.prepareConfig('POST', config, withAuth);
        return ApiFetch.apiRequest(route, config);
    }

    static patch(route, withAuth, config) {
        config = ApiFetch.prepareConfig('PATCH', config, withAuth);
        return ApiFetch.apiRequest(route, config);
    }

    static put(route, withAuth, config) {
        config = ApiFetch.prepareConfig('PUT', config, withAuth);
        return ApiFetch.apiRequest(route, config);
    }

    static delete(route, withAuth, config) {
        config = ApiFetch.prepareConfig('DELETE', config, withAuth);
        return ApiFetch.apiRequest(route, config);
    }

    static apiRequest(route, config) {
        return fetch(Config.API_HOST + route, config)
            .then(ApiFetch.parseJson)
            .then(ApiFetch.handleResponse)
            .catch(err => console.error("API Fetch Error: ", err));
    }

    static parseJson(response){
        return response.json()
            .then(data => ({
                data,
                response
            })).catch(err => console.error("API Fetch parse error: ", err));
    }

    static handleResponse(data) {
        if (!data.response.ok) {
            return Promise.reject(data.data);
        } else {
            return Promise.resolve(data.data);
        }
    }

    static prepareConfig(method, config, withAuth) {
        config = config || {};
        config.method = method;

        if(config.headers === undefined){
            config.headers = {};
        }

        if(withAuth === true){
            config.headers.authorization = 'bearer ' + localStorage.getItem('auth');
        }

        if((method === 'POST' || method === 'PATCH' || method === 'PUT') && config.headers['Content-Type'] === undefined){
            config.headers['Content-Type'] = 'application/json';
        }

        return config;
    }
}
