import React from 'react';
import Config from '../../config'
import {Col, Row} from "antd";

class ApiView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <Row>
                <Col span={24}>
                    <iframe src={Config.API_HOST + '/api'} style={{border: 0, width: '100%', height: 750}}/>
                </Col>
            </Row>
        );
    }

}

export default ApiView;
