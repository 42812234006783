import EventService from "../../core/api-connector/services/event.service";
import { notification } from 'antd';

const Types = {
    EVENT_FETCH_REQUEST: 'EVENT_FETCH_REQUEST',
    EVENT_FETCH_SUCCESS: 'EVENT_FETCH_SUCCESS',
    EVENT_FETCH_ERROR: 'EVENT_FETCH_ERROR',

    PARTICIPATED_EVENT_FETCH_REQUEST: 'PARTICIPATED_EVENT_FETCH_REQUEST',
    PARTICIPATED_EVENT_FETCH_SUCCESS: 'PARTICIPATED_EVENT_FETCH_SUCCESS',
    PARTICIPATED_EVENT_FETCH_ERROR: 'PARTICIPATED_EVENT_FETCH_ERROR',

    SINGLE_EVENT_FETCH_REQUEST: 'SINGLE_EVENT_FETCH_REQUEST',
    SINGLE_EVENT_FETCH_SUCCESS: 'SINGLE_EVENT_FETCH_SUCCESS',
    SINGLE_EVENT_FETCH_ERROR: 'SINGLE_EVENT_FETCH_ERROR',

    PARTICIPATE_REQUEST: 'PARTICIPATE_REQUEST',
    PARTICIPATE_SUCCESS: 'PARTICIPATE_SUCCESS',
    PARTICIPATE_ERROR: 'PARTICIPATE_ERROR'
};

const requestEventAction = () => ({
    type: Types.EVENT_FETCH_REQUEST,
    data: {
        isFetching: true
    }
});

const groupsSuccess = (data) => ({
    type: Types.EVENT_FETCH_SUCCESS,
    data: {
        isFetching: false,
        events: data
    }
});

const groupsError = () => ({
    type: Types.EVENT_FETCH_ERROR,
    data: {
        isFetching: false,
        error: 'Couldn\'t fetch groups!'
    }
});

const getAll = () => {
    return dispatch => {

        dispatch(requestEventAction());
        return EventService.getAll()
            .then((data) => {
                dispatch(groupsSuccess(data));
                return Promise.resolve(data);
            }).catch(err => {
                console.log(err);
                dispatch(groupsError());
                return Promise.reject({success: false, err: err});
            });
    }
};

const requestParticipatedEventAction = () => ({
    type: Types.PARTICIPATED_EVENT_FETCH_REQUEST,
    data: {
        isFetching: true
    }
});

const participatedEventSuccess = (data) => ({
    type: Types.PARTICIPATED_EVENT_FETCH_SUCCESS,
    data: {
        isFetching: false,
        participatedEvents: data
    }
});

const participatedEventError = () => ({
    type: Types.PARTICIPATED_EVENT_FETCH_ERROR,
    data: {
        isFetching: false,
        error: 'Couldn\'t fetch groups!'
    }
});

const getParticipated = () => {
    return dispatch => {

        dispatch(requestParticipatedEventAction());
        return EventService.getParticipated()
            .then((data) => {
                dispatch(participatedEventSuccess(data));
                return Promise.resolve(data);
            }).catch(err => {
                console.log(err);
                dispatch(participatedEventError());
                return Promise.reject({success: false, err: err});
            });
    }
};

const requestSingleEventAction = () => ({
    type: Types.SINGLE_EVENT_FETCH_REQUEST,
    data: {
        isFetching: true
    }
});

const singleEventSuccess = (data) => ({
    type: Types.SINGLE_EVENT_FETCH_SUCCESS,
    data: {
        isFetching: false,
        event: data
    }
});

const singleEventError = () => ({
    type: Types.SINGLE_EVENT_FETCH_ERROR,
    data: {
        isFetching: false,
        error: 'Couldn\'t fetch group!'
    }
});

const getById = (id) => {
    return dispatch => {

        dispatch(requestSingleEventAction());
        return EventService.getById(id)
            .then((data) => {
                dispatch(singleEventSuccess(data));
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(singleEventError());
                return Promise.reject({success: false, err: err});
            });
    }
};



const requestParticipateAction = () => ({
    type: Types.PARTICIPATE_REQUEST,
    data: {
        isFetching: true
    }
});

const participateSuccess = (data) => ({
    type: Types.PARTICIPATE_SUCCESS,
    data: {
        isFetching: false
    }
});

const participateError = () => ({
    type: Types.PARTICIPATE_ERROR,
    data: {
        isFetching: false,
        error: 'Couldn\'t participate!'
    }
});

const participate = (id) => {
    return dispatch => {

        dispatch(requestParticipateAction());
        return EventService.participate(id)
            .then((data) => {
                console.log(data);
                dispatch(participateSuccess(data));
                notification.success({
                    message: 'Erfolg!',
                    description:
                        'Du nimmst am Event ' + data.headline + ' teil!',
                });
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(participateError());
                return Promise.reject({success: false, err: err});
            });
    }
};

export default {
    getAll,
    getById,
    participate,
    getParticipated,
    Types
};
