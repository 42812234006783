import {
    combineReducers
} from 'redux'
import auth from './login.reducer'
import groupData from './group.reducer'
import userData from './user.reducer'
import eventData from './event.reducer'

const LogosArt = combineReducers({
    auth,
    groupData,
    userData,
    eventData
});

export default LogosArt
