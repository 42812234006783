import AuthService from "../../core/api-connector/services/auth.service";
import { notification } from 'antd';

const Types = {
    USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',

    USER_REGISTER_REQUEST: 'USER_REGISTER_REQUEST',
    USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    USER_REGISTER_ERROR: 'USER_REGISTER_ERROR',

    USER_ACTIVATE_REQUEST: 'USER_ACTIVATE_REQUEST',
    USER_ACTIVATE_SUCCESS: 'USER_ACTIVATE_SUCCESS',
    USER_ACTIVATE_ERROR: 'USER_ACTIVATE_ERROR',
};

const requestLoginAction = () => ({
    type: Types.USER_LOGIN_REQUEST,
    login: {
        isFetching: true
    }
});

const loginSuccess = () => ({
    type: Types.USER_LOGIN_SUCCESS,
    login: {
        isFetching: false,
        isAuthenticated: true
    }
});

const loginError = () => ({
    type: Types.USER_LOGIN_ERROR,
    login: {
        isFetching: false,
        error: 'Username and/or password wrong!'
    }
});

const login = (username, password) => {
    return dispatch => {

        dispatch(requestLoginAction());
        return AuthService.login(username, password)
            .then((data) => {
                console.log(data);
                if (data.success === true) {
                    dispatch(loginSuccess());
                } else {
                    let message = data.error === 1 ? 'Benutzername und/oder Passwort sind falsch.' : 'Dein Benutzer ist noch nicht aktiviert!'
                    notification.error({
                        message: 'Fehler!',
                        description: message,
                    });
                    dispatch(loginError());
                }
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(loginError());
                return Promise.reject({success: false, err: err});
            });
    }
};

const requestRegisterAction = () => ({
    type: Types.USER_REGISTER_REQUEST,
    login: {
        isFetching: true
    }
});

const registerSuccess = () => ({
    type: Types.USER_REGISTER_SUCCESS,
    login: {
        isFetching: false
    }
});

const registerError = () => ({
    type: Types.USER_REGISTER_ERROR,
    login: {
        isFetching: false,
        error: 'Registrierung fehlgeschlagen.'
    }
});


const register = (user) => {
    return dispatch => {
        dispatch(requestRegisterAction());
        return AuthService.register(user.username, user.password, user.email)
            .then((data) => {
                console.log(data);
                if (data.success === true) {
                    notification.success({
                        message: 'Erfolg!',
                        description:
                          'Du wurdest erfolgreich registriert! Schaue in deinem Postfach nach der Aktivierungs E-Mail.',
                    });
                    dispatch(registerSuccess());
                } else {
                    notification.error({
                        message: 'Fehler!',
                        description:
                          'Registrierung fehlgeschlagen.',
                    });
                    dispatch(registerError());
                }
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(registerError());
                return Promise.reject({success: false, err: err});
            });
    }
};

const requestActivateAction = () => ({
    type: Types.USER_ACTIVATE_REQUEST,
    login: {
        isFetching: true
    }
});

const activateSuccess = () => ({
    type: Types.USER_ACTIVATE_SUCCESS,
    login: {
        isFetching: false
    }
});

const activateError = () => ({
    type: Types.USER_ACTIVATE_ERROR,
    login: {
        isFetching: false,
        error: 'Aktivierung fehlgeschlagen.'
    }
});


const activate = (key) => {
    return dispatch => {
        dispatch(requestActivateAction());
        return AuthService.activate(key)
            .then((data) => {
                console.log(data);
                if (data.success === true) {
                    notification.success({
                        message: 'Erfolg!',
                        description:
                          'Dein account wurde erfolgreich aktiviert!',
                    });
                    dispatch(activateSuccess());
                } else {
                    notification.error({
                        message: 'Fehler!',
                        description:
                          'Benutzer aktivierung fehlgeschlagen!',
                    });
                    dispatch(activateError());
                }
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(registerError());
                return Promise.reject({success: false, err: err});
            });
    }
};

export default {
    login,
    register,
    activate,
    Types
};
