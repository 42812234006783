import UsersService from "../../core/api-connector/services/users.service";
import { notification } from 'antd';

const Types = {
    SINGLE_USER_FETCH_REQUEST: 'SINGLE_USER_FETCH_REQUEST',
    SINGLE_USER_FETCH_SUCCESS: 'SINGLE_USER_FETCH_SUCCESS',
    SINGLE_USER_FETCH_ERROR: 'SINGLE_USER_FETCH_ERROR',

    RANKING_FETCH_REQUEST: 'RANKING_FETCH_REQUEST',
    RANKING_FETCH_SUCCESS: 'RANKING_FETCH_SUCCESS',
    RANKING_FETCH_ERROR: 'RANKING_FETCH_ERROR',

    COLLECT_EXP_REQUEST: 'COLLECT_EXP_REQUEST',
    COLLECT_EXP_SUCCESS: 'COLLECT_EXP_SUCCESS',
    COLLECT_EXP_ERROR: 'COLLECT_EXP_ERROR',
};

const requestSingleUserAction = () => ({
    type: Types.SINGLE_USER_FETCH_REQUEST,
    userData: {
        isFetching: true
    }
});

const singleUserSuccess = (data) => ({
    type: Types.SINGLE_USER_FETCH_SUCCESS,
    userData: {
        isFetching: false,
        user: data
    }
});

const singleUserError = () => ({
    type: Types.SINGLE_USER_FETCH_ERROR,
    userData: {
        isFetching: false,
        error: 'Couldn\'t fetch User!'
    }
});

const getActor = () => {
    return dispatch => {

        dispatch(requestSingleUserAction());
        return UsersService.getActor()
            .then((data) => {
                dispatch(singleUserSuccess(data));
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(singleUserError());
                return Promise.reject({success: false, err: err});
            });
    }
};

const requestRankingAction = () => ({
    type: Types.RANKING_FETCH_REQUEST,
    userData: {
        isFetching: true
    }
});

const rankingSuccess = (data) => ({
    type: Types.RANKING_FETCH_SUCCESS,
    userData: {
        isFetching: false,
        ranking: data
    }
});

const rankingError = () => ({
    type: Types.RANKING_FETCH_ERROR,
    userData: {
        isFetching: false,
        error: 'Couldn\'t fetch User!'
    }
});

const getRanking = () => {
    return dispatch => {

        dispatch(requestRankingAction());
        return UsersService.getRanking()
            .then((data) => {
                dispatch(rankingSuccess(data));
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(rankingError());
                return Promise.reject({success: false, err: err});
            });
    }
};

const requestCollectExpAction = () => ({
    type: Types.COLLECT_EXP_REQUEST,
    userData: {
        isFetching: true
    }
});

const collectExpSuccess = (data) => {
    notification.success({
        message: 'Erfolg!',
        description:
          'Du hast ' + data.result + ' Exp erhalten.',
    });

    return ({
        type: Types.COLLECT_EXP_SUCCESS,
        userData: {
            isFetching: false,
            exp: data
        }
    })
};

const collectExpError = () => ({
    type: Types.COLLECT_EXP_ERROR,
    userData: {
        isFetching: false,
        error: 'Couldn\'t fetch User!'
    }
});

const collectExp = () => {
    return dispatch => {

        dispatch(requestCollectExpAction());
        return UsersService.collectExp()
            .then((data) => {
                dispatch(collectExpSuccess(data));
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(collectExpError());
                return Promise.reject({success: false, err: err});
            });
    }
};

export default {
    getActor,
    collectExp,
    getRanking,
    Types
};
