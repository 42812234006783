import React from 'react';
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import {Link} from 'react-router-dom';

class LoginForm extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="login-form" style={{width: '100%'}}>
                <Form.Item>
                    {getFieldDecorator('username', {
                        rules: [{ required: true, message: 'Bitte gebe deinen Benutzernamen ein!' }],
                    })(
                        <Input
                            prefix={<Icon type="user" style={{ color: 'rgba(255,255,255,.25)' }} />}
                            placeholder="Benutzername"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Bitte gebe dein Passwort ein!' }],
                    })(
                        <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(255,255,255,.25)' }} />}
                            type="password"
                            placeholder="Passwort"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Login
                    </Button>
                    <Link to="/register">Registrierung</Link>
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create({ name: 'login_form' })(LoginForm);
