import ApiFetch from "./apifetch";
import Config from '../../config';

export default class ApiRequestChecker {
    static checkIfTokenIsValid() {
        return ApiFetch.get('/auth/token/validate', true).then(() => {
            const token = localStorage.getItem('auth');
            const payload = JSON.parse(new Buffer(token.split('.')[1], 'base64').toString());
            if(Math.round(new Date().getTime() / 1000) - payload.exp > -400){
                return ApiFetch.get('/auth/token', true).then((data) => {
                    localStorage.setItem('auth', data.newToken);
                });
            }
        }).catch(() => {
            localStorage.removeItem('auth');
            window.location.reload();
            return Promise.reject(new Error('Token invalid!'));
        })
    }
}
