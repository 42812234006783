import ApiFetch from "../apifetch";
import ApiRequestChecker from '../ApiRequestChecker';

export default class EventService {
    static getAll() {
        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.get('/event/all', true)
        })

    }

    static getParticipated() {
        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.get('/event/participated', true)
        })
    }

    static getById(id) {
        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.get('/event/'+ id, true)
        })
    }

    static participate(id) {
        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.get('/event/'+ id +'/participate', true)
        })
    }
}
