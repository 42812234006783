import React from 'react';
import '../../assets/css/App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import HomeLayout from '../home-layout/HomeLayout';
import 'antd/dist/antd.css';
import DashboardLayout from "../dashboard-layout/DashboardLayout";

function App() {
    return (
        <Router>
            <div style={{height: '100%'}}>
                <Route path="/" exact component={HomeLayout} />
                <Route path="/register" exact component={HomeLayout} />
                <Route path="/activate" component={HomeLayout} />
                <Route path="/dashboard" component={DashboardLayout} />
            </div>
        </Router>
    );
}

export default App;
