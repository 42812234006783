import React from 'react';
import { Form, Icon, Input, Button, Checkbox } from 'antd';
import {Link} from 'react-router-dom';

class RegisterForm extends React.Component {
    state = {
        confirmDirty: false
      };

    handleSubmit = e => {
        
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log('test' , e);
            if (!err) {
                this.props.onSubmit(values);
            } else {
                console.log(err);
            }
        });
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
          callback('Die beiden Passwörter stimmen nicht überein!');
        } else {
          callback();
        }
    };
    
    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="login-form" style={{width: '100%'}}>
                <Form.Item>
                    {getFieldDecorator('username', {
                        rules: [{ required: true, message: 'Bitte gebe deinen Benutzernamen ein!' }],
                    })(
                        <Input
                            prefix={<Icon type="user" style={{ color: 'rgba(255,255,255,.25)' }} />}
                            placeholder="Benutzername"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [
                            { required: true, message: 'Bitte gebe dein Passwort ein!' },
                            {
                                validator: this.validateToNextPassword,
                            },
                        ],
                    })(
                        <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(255,255,255,.25)' }} />}
                            type="password"
                            placeholder="Passwort"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('confirm', {
                        rules: [
                            { required: true, message: 'Bitte wiederhole dein Passwort ein!' },
                            {
                                validator: this.compareToFirstPassword,
                            }
                        ],
                    })(
                        <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(255,255,255,.25)' }} />}
                            type="password"
                            placeholder="Passwort wiederholen"
                            onBlur={this.handleConfirmBlur}
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [
                            { required: true, message: 'Bitte gebe deine E-mail Adresse ein!' },
                            {
                                type: 'email',
                                message: 'Bitte gebe eine valide E-mail Adresse ein!',
                            },
                        ],
                    })(
                        <Input
                            prefix={<Icon type="mail" style={{ color: 'rgba(255,255,255,.25)' }} />}
                            placeholder="Email"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        Registrieren
                    </Button>
                    <Link to="/">Zurück zum Login</Link>
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create({ name: 'register_form' })(RegisterForm);
