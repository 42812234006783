import ApiFetch from "../apifetch";
import ApiRequestChecker from '../ApiRequestChecker';

export default class UsersService {

    static getActor() {
        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.get('/user/', true)
        })
    }

    static collectExp() {
        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.get('/user/exp', true)
        })
    }

    static getRanking() {
        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.get('/user/ranking', true)
        })
    }
}
