import GroupsService from "../../core/api-connector/services/groups.service";
import { notification } from 'antd';

const Types = {
    GROUP_COLLECT_REQUEST: 'GROUP_COLLECT_REQUEST',
    GROUP_COLLECT_SUCCESS: 'GROUP_COLLECT_SUCCESS',
    GROUP_COLLECT_ERROR: 'GROUP_COLLECT_ERROR',

    GROUP_REMOVE_REQUEST: 'GROUP_REMOVE_REQUEST',
    GROUP_REMOVE_SUCCESS: 'GROUP_REMOVE_SUCCESS',
    GROUP_REMOVE_ERROR: 'GROUP_REMOVE_ERROR',

    VERIFY_REQUEST: 'VERIFY_REQUEST',
    VERIFY_SUCCESS: 'VERIFY_SUCCESS',
    VERIFY_ERROR: 'VERIFY_ERROR',

    GROUP_FETCH_REQUEST: 'GROUP_FETCH_REQUEST',
    GROUP_FETCH_SUCCESS: 'GROUP_FETCH_SUCCESS',
    GROUP_FETCH_ERROR: 'GROUP_FETCH_ERROR',

    SINGLE_GROUP_FETCH_REQUEST: 'SINGLE_GROUP_FETCH_REQUEST',
    SINGLE_GROUP_FETCH_SUCCESS: 'SINGLE_GROUP_FETCH_SUCCESS',
    SINGLE_GROUP_FETCH_ERROR: 'SINGLE_GROUP_FETCH_ERROR',
};

const collectGroupAction = () => ({
    type: Types.GROUP_COLLECT_REQUEST,
    groupData: {
        isFetching: true
    }
});

const groupCollectSuccess = () => ({
    type: Types.GROUP_COLLECT_SUCCESS,
    groupData: {
        isFetching: false
    }
});

const groupCollectError = () => ({
    type: Types.GROUP_COLLECT_ERROR,
    groupData: {
        isFetching: false,
        error: 'Couldn\'t create group!'
    }
});

const collect = (group) => {

    return dispatch => {
        dispatch(collectGroupAction());
        return GroupsService.collectById(group.id)
            .then((data) => {
                if (data.success === true) {
                    notification.success({
                        message: 'Erfolg!',
                        description:
                          'Die Gruppe ' + group.name + ' wurde aktiviert!',
                    });
                    dispatch(groupCollectSuccess(data));
                } else {
                    notification.error({
                        message: 'Fehler!',
                        description:
                          'Die Gruppe ' + group.name + ' konnte nicht aktiviert werden!',
                    });
                    dispatch(groupCollectError(data));
                }
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(groupCollectError());
                return Promise.reject({success: false, err: err});
            });
    }
};

const removeGroupAction = () => ({
    type: Types.GROUP_REMOVE_REQUEST,
    groupData: {
        isFetching: true
    }
});

const groupRemoveSuccess = () => ({
    type: Types.GROUP_REMOVE_SUCCESS,
    groupData: {
        isFetching: false
    }
});

const groupRemoveError = () => ({
    type: Types.GROUP_REMOVE_ERROR,
    groupData: {
        isFetching: false,
        error: 'Couldn\'t create group!'
    }
});

const remove = (group) => {

    return dispatch => {
        dispatch(removeGroupAction());
        return GroupsService.removeById(group.id)
            .then((data) => {
                if (data.success === true) {
                    notification.success({
                        message: 'Erfolg!',
                        description:
                          'Die Gruppe ' + group.name + ' wurde abgelegt!',
                    });
                    dispatch(groupRemoveSuccess(data));
                } else {
                    notification.error({
                        message: 'Fehler!',
                        description:
                          'Die Gruppe ' + group.name + ' konnte nicht abgelegt werden!',
                    });
                    dispatch(groupRemoveError(data));
                }
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(groupRemoveError());
                return Promise.reject({success: false, err: err});
            });
    }
};

const verifyAction = () => ({
    type: Types.VERIFY_REQUEST,
    groupData: {
        isFetching: true
    }
});

const verifySuccess = () => ({
    type: Types.VERIFY_SUCCESS,
    groupData: {
        isFetching: false
    }
});

const verifyError = (err) => ({
    type: Types.VERIFY_ERROR,
    groupData: {
        isFetching: false,
        error: err
    }
});

const verify = () => {

    return dispatch => {
        dispatch(verifyAction());
        return GroupsService.verify()
            .then((data) => {
                if (data.success === true) {
                    notification.success({
                        message: 'Erfolg!',
                        description:
                          'Du wurdest erfolgreich verifiziert!',
                    });
                    dispatch(verifySuccess(data));
                } else {
                    notification.error({
                        message: 'Fehler!',
                        description:
                          'Bei der verifizierung ist ein Fehler aufgetreten! Bist du mit dem TeamSpeak Server verbunden?',
                    });
                    dispatch(verifyError(data));
                }
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(verifyError(err));
                return Promise.reject({success: false, err: err});
            });
    }
};

const requestGroupAction = () => ({
    type: Types.GROUP_FETCH_REQUEST,
    groupData: {
        isFetching: true
    }
});

const groupsSuccess = (data) => ({
    type: Types.GROUP_FETCH_SUCCESS,
    groupData: {
        isFetching: false,
        groups: data
    }
});

const groupsError = () => ({
    type: Types.GROUP_FETCH_ERROR,
    groupData: {
        isFetching: false,
        error: 'Couldn\'t fetch groups!'
    }
});

const getAll = () => {
    return dispatch => {

        dispatch(requestGroupAction());
        return GroupsService.getAll()
            .then((data) => {
                data.sort((a, b) => {
                    if (a.levelRequired > b.levelRequired) return -1;
                    if (a.levelRequired < b.levelRequired) return 1;
                    return 0;
                })
                dispatch(groupsSuccess(data));
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(groupsError());
                return Promise.reject({success: false, err: err});
            });
    }
};

const requestSingleGroupAction = () => ({
    type: Types.SINGLE_GROUP_FETCH_REQUEST,
    groupData: {
        isFetching: true
    }
});

const singleGroupSuccess = (data) => ({
    type: Types.SINGLE_GROUP_FETCH_SUCCESS,
    groupData: {
        isFetching: false,
        group: data
    }
});

const singleGroupError = () => ({
    type: Types.SINGLE_GROUP_FETCH_ERROR,
    groupData: {
        isFetching: false,
        error: 'Couldn\'t fetch group!'
    }
});

const getById = (id) => {
    return dispatch => {

        dispatch(requestSingleGroupAction());
        return GroupsService.getById(id)
            .then((data) => {
                dispatch(singleGroupSuccess(data));
                return Promise.resolve(data);
            }).catch(err => {
                dispatch(singleGroupError());
                return Promise.reject({success: false, err: err});
            });
    }
};

export default {
    collect,
    remove,
    verify,
    getAll,
    getById,
    Types
};
