import React from 'react';
import { Card } from 'antd';
import LoginForm from './login.form'
import RegisterForm from './register.form'
import { connect } from "react-redux";
import LoginAction from '../../modules/actions/login.action';
import {Redirect, withRouter, Route} from "react-router-dom";
import Logo from '../../assets/img/logo-gradient.png'
import Activate from './Activate';

const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return (
      React.createElement(component, finalProps)
    );
  }
  
  const PropsRoute = ({ component, ...rest }) => {
    return (
      <Route {...rest} render={routeProps => {
        return renderMergedProps(component, routeProps, rest);
      }}/>
    );
  }

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.loginUser = this.loginUser.bind(this);
    }

    loginUser = (user) => {
        this.props.login(user.username, user.password).then((data) => {
            if (data.success === true) {
                this.props.history.push('/dashboard');
            }
        });
    }

    registerUser = (user) => {
        this.props.register(user).then((data) => {
            if (data.success === true) {
                this.props.history.push('/');
            }
        });
    }

    render() {

        return (
            <Card style={{ width: '100%', background: 'rgba(10, 18, 33, 0.92)' }}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 80}}>
                    <img src={Logo} style={{width: '50%'}}/>
                </div>
                <div className="login-holder">
                    <PropsRoute path="/" exact component={LoginForm} onSubmit={this.loginUser}/>
                    <PropsRoute path="/register" component={RegisterForm} onSubmit={this.registerUser}/>
                    <Route path="/activate/:key" component={Activate}/>
                </div>
            </Card>
        );
    }

}

const mapStateToProps = state => ({
    login: state.login
});

const mapDispatchToProps = dispatch => ({
    login: (username, password) => dispatch(LoginAction.login(username, password)),
    register: (user) => dispatch(LoginAction.register(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Login));
