import React from 'react';
import {Card, Typography, Row, Col, Icon, Button, Progress, Statistic, Tooltip} from 'antd';
import { connect } from "react-redux";
import UserAction from '../../modules/actions/user.action';
import GroupAction from '../../modules/actions/group.action';
import LevelCalculator from '../../core/LevelCalculator';
import {withRouter} from "react-router-dom";
import verified from '../../assets/group-icons/verified.svg'
const { Countdown } = Statistic;


const { Title, Paragraph } = Typography;
const { Meta } = Card;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
    }

    countdownFinish = () => {
        this.props.loadActor();
    }

    render() {
        const groups = JSON.parse(JSON.stringify(this.props.groupData.groups));
        if (this.props.groupData.groups.length > 0) {
            groups.unshift({name: 'Verifiziert', icon: 'verified.svg'});
        }
        return (
            <div>
                <Row gutter={16}>
                    <Col span={24}>
                        <Row gutter={16} style={{marginBottom: 16}}>
                            <Col xs={24} sm={24} md={12} lg={6} style={{marginTop: 10}}>
                                <Card loading={this.props.userData.user === null} style={{minHeight: 106}}>
                                {this.props.userData.user &&
                                <Meta
                                    avatar={
                                        <Icon type="user" />
                                    }
                                    title="Benutzer"
                                    description={
                                        <div style={{display: 'inline-flex', alignItems: 'center'}}>
                                            <span>{this.props.userData.user.username} </span>
                                            {this.props.userData.user.teamspeakId > 0 ? <Tooltip placement="bottom" title="Verifziert"><img src={verified} style={{width: 16, marginLeft: 2}}/></Tooltip> : ''}
                                        </div>}
                                />
                                }

                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} style={{marginTop: 10}}>
                                <Card loading={this.props.userData.user === null} style={{minHeight: 106}}>
                                    <Meta
                                        avatar={
                                            <Icon type="bulb" />
                                        }
                                        title="Level"
                                        description={this.props.userData.user !== null ? (LevelCalculator.calcLevel(this.props.userData.user.exp)) : ''}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} style={{marginTop: 10}}>
                                <Card loading={this.props.userData.user === null} style={{minHeight: 106}}>
                                    <Meta
                                        avatar={
                                            <Icon type="rise" />
                                        }
                                        title="Level Fortschritt"
                                        description={<Progress percent={this.props.userData.user !== null ? (LevelCalculator.calcPercentageForNextLevel(this.props.userData.user.exp)) : 0} size="small" status="active" />}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} style={{marginTop: 10}}>
                                <Card loading={this.props.userData.user === null} style={{minHeight: 106}}>
                                    {
                                        this.props.userData.user !== null && new Date().getTime() / 1000 >= this.props.userData.user.expCollectedAt + (24 * 60 * 60) &&
                                        <Meta
                                        avatar={
                                            <Icon type="gift" />
                                        }
                                        title="Exp sammeln"
                                        description={<Button type="primary" onClick={() => {this.props.collectExp()}}>Einsammeln</Button>}
                                        />
                                    }
                                    {
                                        this.props.userData.user !== null && new Date().getTime() / 1000 < this.props.userData.user.expCollectedAt + (24 * 60 * 60) &&
                                        <div>
                                            <Meta
                                            avatar={
                                                <Icon type="gift" />
                                            }
                                            title="Exp sammeln"
                                            />
                                            <Countdown onFinish={this.countdownFinish} value={(this.props.userData.user.expCollectedAt + (24 * 60 * 60)) * 1000} />
                                        </div>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{marginTop: 16}}>
                    <Col>
                        <Title level={3}>Gruppen</Title>
                    </Col>
                </Row>
                <Row style={{marginTop: 16, marginBottom: 16}} gutter={16}>
                    {
                        this.props.userData.user !== null && this.props.groupData.groups.length > 0 &&
                        groups.map((group) => {
                            const images = require.context('../../assets/group-icons', true);
                            let img = images('./' + group.icon);
                            return (
                                <Col xs={24} sm={24} md={12} lg={4}  key={group.id} style={{marginBottom: 16}}>
                                    <Card
                                        cover={
                                            <div style={{display: 'flex', width: '100%', height: 200, justifyContent: 'center', alignItems: 'center'}}>
                                                <img src={img} style={
                                                    {
                                                        width: 128,
                                                        height: 128,
                                                        filter: (group.levelRequired > LevelCalculator.calcLevel(this.props.userData.user.exp) ||
                                                         !(this.props.userData.user.teamspeakId > 0)) && group.name !== "Verifiziert" ? 'grayscale(100%)':''}}/>
                                            </div>
                                        }
                                        style={{minHeight: 379, userSelect: 'none'}}
                                    >
                                        {
                                            group.name !== "Verifiziert" && this.props.userData.user.teamspeakId > 0 &&
                                            <div>
                                                <Meta title={group.name} description={"Level " + group.levelRequired + " benötigt"} />
                                                <Button onClick={() => {this.props.collectGroup(group)}} type="primary" style={{width: '100%', marginTop: 10}} disabled={(group.levelRequired > LevelCalculator.calcLevel(this.props.userData.user.exp))}>Aktivieren</Button>
                                                <Button onClick={() => {this.props.removeGroup(group)}} className="secondary" style={{width: '100%', marginTop: 10}} disabled={(group.levelRequired > LevelCalculator.calcLevel(this.props.userData.user.exp))}>Ablegen</Button>
                                            </div>
                                        }

                                        {
                                            group.name !== "Verifiziert" && !(this.props.userData.user.teamspeakId > 0) &&
                                            <div>
                                                <Meta title={group.name} description={"Level " + group.levelRequired + " und Verifiziert benötigt"} />
                                                <Button type="primary" style={{width: '100%', marginTop: 10}} disabled={true}>Aktivieren</Button>
                                                <Button className="secondary" style={{width: '100%', marginTop: 10}} disabled={true}>Ablegen</Button>
                                            </div>
                                        }

                                        {    group.name === "Verifiziert" &&
                                            <div>
                                                <Meta title={group.name} description={"Verifiziere dich um Gruppen erhalten zu können."} />
                                                <Button onClick={() => {this.props.verify()}} type="primary" style={{width: '100%', marginTop: 20}}>Verifizieren</Button>
                                            </div>
                                        }

                                    </Card>
                                </Col>
                            )
                        })
                    }

                </Row>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    groupData: state.groupData,
    userData: state.userData
});

const mapDispatchToProps = dispatch => ({
    collectExp: () => {
        dispatch(UserAction.collectExp()).then(() => {
            dispatch(UserAction.getActor())
        })
    },
    loadActor: () => dispatch(UserAction.getActor()),
    verify: () => {
        dispatch(GroupAction.verify()).then(() => {
            dispatch(UserAction.getActor())
        })
    },
    collectGroup: (group) => dispatch(GroupAction.collect(group)),
    removeGroup: (group) => dispatch(GroupAction.remove(group)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Dashboard));
