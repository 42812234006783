import React from 'react';
import { connect } from "react-redux";
import LoginAction from '../../modules/actions/login.action';
import { withRouter } from "react-router-dom";
import { Typography } from 'antd';

const { Title, Text  } = Typography;

class Activate extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount () {
        const key = this.props.match.params.key;
        if (key !== undefined) {
            this.props.activate(key).then((data) => {
                this.props.history.push('/');
            })
        } else {
            this.props.history.push('/');
        }
    }

    render() {

        return (
            <div>
                <Title level={3}>Aktivierung</Title>
                <Text>Dein Benutzer account wurde erfolgreich aktiviert!</Text>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    login: state.login
});

const mapDispatchToProps = dispatch => ({
    activate: (key) => dispatch(LoginAction.activate(key)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Activate));
