import {Avatar, Button, Card, Col, Icon, Row, Tag, Typography} from 'antd';
import ReactMarkdown from 'react-markdown';
import React from 'react';
import EventAction from '../../../modules/actions/event.action';
import {connect} from 'react-redux';

const {Title, Paragraph} = Typography;
const {Meta} = Card;

class EventCard extends React.Component {
    render() {
        const event = this.props.event;
        const images = require.context('../../../assets/group-icons', true);
        let img = null;
        if (event.serverGroup) {
            img = images('./' + event.serverGroup.icon);
        }
        return (
            <Col key={event.id} xs={24} sm={24} md={12} lg={12} style={{marginBottom: 16}}>
                <Card
                    cover={<img alt="event" src={event.headerImage}/>}
                >
                    <Meta
                        title={<div>
                            <div className="title">
                                {event.headline}
                            </div>
                            <div className="tags" style={{marginTop: '10px'}}>
                                {
                                    event.eventType === 1 &&
                                    <Tag color="#f50">Community-Event</Tag>
                                }
                                {
                                    event.eventType === 2 &&
                                    <Tag color="#87d068">Servergruppen-Event</Tag>
                                }
                                {
                                    event.eventType === 3 &&
                                    <Tag color="#108ee9">EXP-Event</Tag>
                                }
                            </div>
                        </div>}
                        description={<ReactMarkdown>{event.text}</ReactMarkdown>}
                    />
                    {
                        event.serverGroup &&
                        <div className="group-info" style={{marginBottom: 20}}>
                            <Title level={4}>Servergruppe</Title>
                            <Row>
                                <Col xs={3}>
                                    <Avatar size={64} src={img} />
                                </Col>
                                <Col xs={21}>
                                    <Title level={4}>{event.serverGroup.name}</Title>
                                    <Paragraph>Exklusive Servergruppe</Paragraph>
                                </Col>
                            </Row>
                        </div>
                    }
                    <Row gutter={16}>
                        {
                            event.eventType === 1 &&
                            this.props.userData &&
                            this.props.userData.user &&
                            !event.participants.find(u => u.id === this.props.userData.user.id) &&
                            <Col sm={24}>
                                <Button type="primary" style={{width: '100%'}}
                                        onClick={() => this.props.participate(event.id)}>Teilnehmen</Button>
                            </Col>
                        }
                        {
                            event.eventType === 1 &&
                            this.props.userData &&
                            this.props.userData.user &&
                            event.participants.find(u => u.id === this.props.userData.user.id) &&
                            <Col sm={24}>
                                <Paragraph style={{
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    margin: 0,
                                    color: '#1a7cd6'
                                }}><Icon type="check"/> Du nimmst teil</Paragraph>
                            </Col>
                        }
                        {
                            event.eventType === 2 &&
                            this.props.userData &&
                            this.props.userData.user &&
                            !event.participants.find(u => u.id === this.props.userData.user.id) &&
                            <Col sm={24}>
                                <Button type="primary" style={{width: '100%'}}
                                        onClick={() => this.props.participate(event.id)}>Gruppe einsammeln</Button>
                            </Col>
                        }
                        {
                            event.eventType === 2 &&
                            this.props.userData &&
                            this.props.userData.user &&
                            event.participants.find(u => u.id === this.props.userData.user.id) &&
                            <Col sm={24}>
                                <Paragraph style={{
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    margin: 0,
                                    color: '#1a7cd6'
                                }}><Icon type="check"/> Du hast die Gruppe eingesammelt</Paragraph>
                            </Col>
                        }
                    </Row>
                </Card>
            </Col>)
    }
}

const mapStateToProps = state => ({
    userData: state.userData
});

const mapDispatchToProps = dispatch => ({
    participate: (id) => {
        dispatch(EventAction.participate(id)).then(() => {
            dispatch(EventAction.getAll())
            dispatch(EventAction.getParticipated())
        })
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventCard);
