import React from 'react';
import {Route, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Layout, Menu, Breadcrumb, Icon} from 'antd';
import Dashboard from "../dashboard/Dashboard";
import Event from "../events/Event";
import ApiView from "../api/ApiView";
import GroupAction from "../../modules/actions/group.action";
import userAction from '../../modules/actions/user.action';
import Ranking from '../ranking/Ranking';

const {SubMenu} = Menu;
const {Header, Content, Sider} = Layout;

class DashboardLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        if(localStorage.getItem('auth') === null){
            this.props.history.push('/');
        } else {
            this.props.getAllGroups();
            this.props.loadActor();
        }
    }

    onLogout = () => {
        localStorage.removeItem('auth');
        this.props.history.push('/');
    }

    render() {
        let key;
        switch (this.props.location.pathname) {
            case '/dashboard':
                key = 'dashboard';
                break;
            case '/dashboard/user':
                key = 'user';
                break;
            case '/dashboard/projects':
                key = 'user';
                break;
            case '/dashboard/settings/api':
                key = 'api';
                break;
        }

        const openKeyArray = ['projects'];

        if(key === 'api' || key === 'user' || key === 'teams' || key === 'permission'){
            openKeyArray.push('settings')
        }

        return (
            <Layout style={{height: '100%'}}>
                <Header className="header" style={{background: '#0c1525'}}>
                    <div className="logo-holder">
                        <div className="logo"/>
                    </div>
                </Header>
                <Layout>
                    <Sider width={200} style={{background: '#fff'}}>
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={[key]}
                            defaultOpenKeys={openKeyArray}
                            style={{height: '100%', borderRight: 0}}
                        >
                            <Menu.Item key="dashboard" onClick={() => {this.props.history.push('/dashboard');}}><Icon type="home" /> Home</Menu.Item>

                            <Menu.Item key="teamspeak"><a href="ts3server://logosart.de" target="_blank" rel="noopener noreferrer"><Icon type="customer-service" /> TeamSpeak</a></Menu.Item>
                            <Menu.Item key="event" onClick={() => {this.props.history.push('/dashboard/event');}}><Icon type="exclamation-circle" /> Event</Menu.Item>
                            <Menu.Item key="ranking" onClick={() => {this.props.history.push('/dashboard/ranking');}}><Icon type="trophy" /> Rangliste</Menu.Item>
                            <Menu.Item key="logout" onClick={this.onLogout}><Icon type="logout" /> Logout</Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout style={{padding: '0 24px 24px', height: '100%', overflowY: 'scroll', background: '#060a10', color: '#fafafa'}}>

                        <Content
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                            }}
                        >
                            <Route path="/dashboard" exact component={Dashboard} />
                            <Route path="/dashboard/settings/api" component={ApiView} />
                            <Route path="/dashboard/event" component={Event} />
                            <Route path="/dashboard/ranking" component={Ranking} />
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        );
    }

}

const mapStateToProps = state => ({
    login: state.auth.login,
    project: state.groupData
});

const mapDispatchToProps = dispatch => ({
    getProjectById: (id) => dispatch(GroupAction.getById(id)),
    loadActor: () => dispatch(userAction.getActor()),
    getAllGroups: () => dispatch(GroupAction.getAll()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DashboardLayout));
