import ApiFetch from "../apifetch";
import ApiRequestChecker from '../ApiRequestChecker';

export default class GroupsService {
    static getAll() {
        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.get('/groups/all', true)
        })

    }

    static getById(id) {
        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.get('/groups/'+id, true)
        })
    }

    static collectById(id) {
        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.get('/groups/collect/'+id, true)
        })
    }

    static verify() {
        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.get('/groups/verify', true)
        })
    }

    static removeById(id) {
        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.get('/groups/remove/'+id, true)
        })
    }

    static create(project) {
        const config = {
            body: JSON.stringify(project)
        };

        return ApiRequestChecker.checkIfTokenIsValid().then(() => {
            return ApiFetch.post('/groups', true, config)
        })
    }
}
