import GroupAction from "../actions/group.action";
import _ from "lodash";

const defaultState = {
    isFetching: false,
    error: false,
    groups: [],
    group: null
};

const groupReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GroupAction.Types.GROUP_COLLECT_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            return newState;
        }
        case GroupAction.Types.GROUP_COLLECT_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            return newState;
        }
        case GroupAction.Types.GROUP_COLLECT_ERROR: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            newState.error = action.groupData.error;
            return newState;
        }

        case GroupAction.Types.GROUP_REMOVE_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            return newState;
        }
        case GroupAction.Types.GROUP_REMOVE_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            return newState;
        }
        case GroupAction.Types.GROUP_REMOVE_ERROR: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            newState.error = action.groupData.error;
            return newState;
        }

        case GroupAction.Types.VERIFY_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            return newState;
        }
        case GroupAction.Types.VERIFY_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            return newState;
        }
        case GroupAction.Types.VERIFY_ERROR: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            newState.error = action.groupData.error;
            return newState;
        }

        case GroupAction.Types.GROUP_FETCH_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            return newState;
        }
        case GroupAction.Types.GROUP_FETCH_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            newState.groups = action.groupData.groups;
            return newState;
        }
        case GroupAction.Types.GROUP_FETCH_ERROR: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            newState.error = action.groupData.error;
            return newState;
        }

        case GroupAction.Types.SINGLE_GROUP_FETCH_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            return newState;
        }
        case GroupAction.Types.SINGLE_GROUP_FETCH_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            newState.group = action.groupData.group;
            return newState;
        }
        case GroupAction.Types.SINGLE_GROUP_FETCH_ERROR: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.groupData.isFetching;
            newState.error = action.groupData.error;
            return newState;
        }
        default:
            return state;
    }
};

export default groupReducer;
