import ApiFetch from "../apifetch";

export default class AuthService {
    static login(username, password) {
        const config = {
            body: JSON.stringify({username, password})
        };

        return ApiFetch.post('/auth/login', false, config).then((data) => {
            if(data.status !== 404){
                localStorage.setItem('auth', data.access_token);
                return Promise.resolve(data);
            } else {
                throw new Error('Login Failed');
            }
        });
    }

    static register(username, password, email) {
        const config = {
            body: JSON.stringify({username, email, password})
        };

        return ApiFetch.post('/auth/register', false,  config)
    }

    static activate(key) {
        return ApiFetch.get('/auth/activate/'+key)
    }
}
