import React from 'react';
import {Table, Col, Row, Typography} from 'antd';
import {connect} from 'react-redux';
import UserAction from '../../modules/actions/user.action';
import LevelCalculator from '../../core/LevelCalculator';

const {Title} = Typography;


const columns = [
    {
        title: 'Rang',
        dataIndex: 'rank',
        key: 'rank',
    },
    {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
    },
    {
        title: 'Level',
        dataIndex: 'exp',
        key: 'exp',
        render: text => LevelCalculator.calcLevel(text),
    }
];

const addRank = (list) => {
    const newList = [];
    let i = 1;
    list.forEach(u => {
        newList.push({rank: i, ...u})
        i++;
    })

    return newList;
}


class Ranking extends React.Component {

    componentWillMount() {
        this.props.loadRanking();
    }

    render() {
        console.log(this.props.userData);
        return (
            <div>
                <Row style={{marginTop: 16}}>
                    <Col>
                        <Title level={3}>Rangliste</Title>
                    </Col>
                </Row>
                <Row style={{marginTop: 32, marginBottom: 32}} gutter={32}>
                    {
                        this.props.userData.ranking &&
                        <Table columns={columns} dataSource={addRank(this.props.userData.ranking)} pagination={false}/>
                    }
                </Row>
            </div>)
    }
}


const mapStateToProps = state => ({
    userData: state.userData
});

const mapDispatchToProps = dispatch => ({
    loadRanking: () => {
        dispatch(UserAction.getRanking())
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ranking);
