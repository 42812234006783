import React from 'react';
import {Col, Row, Typography} from 'antd';
import {connect} from 'react-redux';
import EventAction from '../../modules/actions/event.action';
import EventCard from './components/EventCard';

const {Title} = Typography;


class Event extends React.Component {

    componentWillMount() {
        this.props.loadAllEvents();
    }

    render() {
        return (
            <div>
                <Row style={{marginTop: 16}}>
                    <Col>
                        <Title level={3}>Events</Title>
                    </Col>
                </Row>
                <Row style={{marginTop: 32, marginBottom: 32}} gutter={32}>
                    {
                        this.props.eventData &&
                        this.props.eventData.events &&
                        !this.props.eventData.events.find(e => e === null) &&
                        this.props.eventData.events.map((event) => {
                            return <EventCard event={event}/>
                        })
                    }
                </Row>
                <Row style={{marginTop: 16}}>
                    <Col>
                        <Title level={3}>Events an denen du teilgenommen hast</Title>
                    </Col>
                </Row>
                <Row style={{marginTop: 32, marginBottom: 32}} gutter={32}>
                    {
                        this.props.eventData &&
                        this.props.eventData.participatedEvents &&
                        !this.props.eventData.participatedEvents.find(e => e === null) &&
                        this.props.eventData.participatedEvents.map((event) => {
                            return <EventCard event={event}/>
                        })
                    }
                </Row>
            </div>)
    }
}


const mapStateToProps = state => ({
    eventData: state.eventData,
    userData: state.userData
});

const mapDispatchToProps = dispatch => ({
    loadAllEvents: () => {
        dispatch(EventAction.getAll())
        dispatch(EventAction.getParticipated())
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Event);
