import UserAction from "../actions/user.action";
import _ from "lodash";

const defaultState = {
    isFetching: false,
    error: false,
    user: null,
    ranking: null
};

const userReducer = (state = defaultState, action) => {
    switch (action.type) {
        case UserAction.Types.SINGLE_USER_FETCH_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.userData.isFetching;
            return newState;
        }
        case UserAction.Types.SINGLE_USER_FETCH_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.userData.isFetching;
            newState.user = action.userData.user;
            return newState;
        }
        case UserAction.Types.SINGLE_USER_FETCH_ERROR: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.userData.isFetching;
            newState.error = action.userData.error;
            return newState;
        }

        case UserAction.Types.RANKING_FETCH_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.userData.isFetching;
            return newState;
        }
        case UserAction.Types.RANKING_FETCH_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.userData.isFetching;
            newState.ranking = action.userData.ranking;
            return newState;
        }
        case UserAction.Types.RANKING_FETCH_ERROR: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.userData.isFetching;
            newState.error = action.userData.error;
            return newState;
        }

        case UserAction.Types.COLLECT_EXP_REQUEST: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.userData.isFetching;
            return newState;
        }
        case UserAction.Types.COLLECT_EXP_SUCCESS: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.userData.isFetching;
            newState.exp = action.userData.exp;
            return newState;
        }
        case UserAction.Types.COLLECT_EXP_ERROR: {
            let newState = _.cloneDeep(state);
            newState.isFetching = action.userData.isFetching;
            newState.error = action.userData.error;
            return newState;
        }
        default:
            return state;
    }
};

export default userReducer;
